/** @deprecated use `@wss/error-tracking/sentry` instead */
// eslint-disable-next-line no-restricted-imports
import type { BrowserOptions } from "@sentry/browser";
import {
  getRecentSentryBreadcrumbs,
  isLoggingNetworkErrorDuringPageUnload,
  isProbablyBlockedByAdblockNetworkError,
  isGoogleRecaptchaTimeout,
  isNonErrorDomEventRejection,
} from "@wss/error-tracking/inbound-filters";
import { env } from "./Environment";
import Store from "./Store";

const sentryUrls = {
  local: null,
  development:
    "https://c984214f46614ded89dd5ec7cb24ba7f@sentry.dev.clarkinc.biz/446",
  test: "https://4caf865843954418900c2701e7b69762@sentry.test.clarkinc.biz/335",
  production:
    "https://43c4e46210ff42afa6eb2e16f20c62a4@www.webstaurantstore.com/sentry/297",
};

const hasConductrics = !!document.querySelector(
  'script[src$="/cdt/js/script.js"]'
);
const conductricsErrors = [
  "Minified React error #425", // Text content error
  "Minified React error #418", // Hydration error
  "Minified React error #423", // Hydration recover error
  "Text content does not match server-rendered HTML",
  "Hydration failed because the server rendered HTML didn't match the client",
  "There was an error while hydrating but React was able to recover by instead client rendering the entire root",
];
/** @see {@link https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-ignore-errors Sentry Docs} */
const ignoreErrors: NonNullable<BrowserOptions["ignoreErrors"]> = [
  "Failed to fetch",
  "Loading chunk",
  "Loading CSS chunk",
  "NetworkError when attempting to fetch resource",
  "Load failed",
  "ResizeObserver",
];
if (hasConductrics) {
  ignoreErrors.push(...conductricsErrors);
}

import(/* webpackChunkName: "vendors~sentry-browser" */ "@sentry/browser").then(
  // eslint-disable-next-line consistent-return
  ({ init, configureScope }) => {
    // Change the line below to `const url = sentryUrls.dev` to enable local logging.
    const url = sentryUrls[env.current];

    if (!url) {
      // eslint-disable-next-line no-console
      return console.info("Skipping Sentry init for local development");
    }

    init({
      dsn: url,
      ignoreErrors,
      allowUrls: [
        "https://localhost:5001",
        "https://www.loc.webstaurantstore.com/",
        "https://www.dev.webstaurantstore.com/",
        "https://argo.dev.webstaurantstore.com/",
        "https://www.test.webstaurantstore.com/",
        "https://www.webstaurantstore.com/",
        "https://argo.webstaurantstore.com/",
      ],
      beforeSend(event, hint) {
        if (hint) {
          if (isGoogleRecaptchaTimeout(hint)) return null;
          if (isNonErrorDomEventRejection(hint)) return null;
        }

        const breadcrumbs = getRecentSentryBreadcrumbs(event);
        if (!breadcrumbs) return event;
        if (isProbablyBlockedByAdblockNetworkError(breadcrumbs)) return null;
        if (isLoggingNetworkErrorDuringPageUnload(breadcrumbs)) return null;

        return event;
      },
    });

    configureScope((scope) => {
      scope.setUser({ id: Store.getState().user.index });
    });
  }
);
